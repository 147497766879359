import { Client } from "./baseurl";

export const getEvents = async(keywords:string | null, date:string | null, provinceId:string | null, placeId:string | null, locationId:string | null) => {
    const request = {
            "keywords":keywords,
            "place":placeId,
            "date":date,
            "provinceId":provinceId,
            "locationId": locationId,
            "eventHash": null,
            "groupId": null
    }
    const response = await Client.post(`/events`,request);
    return response.data;
}


export const getEventsItemTypeGroup = async(groupId:string) => {
    const request = {
            "keywords":null,
            "place":null,
            "date":null,
            "provinceId":null,
            "locationId": null,
            "eventHash": null,
            "groupId": groupId
    }
    const response = await Client.post(`/events`,request);
    return response.data;
}

export const getSelectedEvent = async(eventHash:string, vendor?:string) => {
    const response = await Client.get(`/events/${eventHash}?vendor=${vendor ? vendor : ''}`);
    const userId: string = response.headers['x-ew-ruid'];
    return {data: response.data, userId: userId};
}


