import {useEffect, useRef, useState} from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { checkPaymentModo, paymentIntetionModo } from '../../../api/purchase';
import MODO from '../../../utilities/MODO';
import Spinner from '../Spinner';
import { useNavigate, useSearchParams } from 'react-router-dom';
/* import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'; */

const TipoPagoModo = () => {
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState<boolean>(true);
    const modoDataRef = useRef<any>(null);
    const modo = new MODO();
    const navigate = useNavigate();
    const [waitingModal, setWaitingModal] = useState<boolean>(false);

    useEffect(() => {
        /* window.onpageshow = (event) => {
            if (event.persisted) {
                window.location.reload();
            }
        }; */

        const modoModal = localStorage.getItem('modoModal');

        if(!modoModal) {
            showModal();
        } else {
            onCloseModo();
            setLoading(false);
        }
    }, []);

    const createPaymentIntention = async () => {
        const res = await paymentIntetionModo();
        return res
    }

    const showModal = async () => {
        const modalData = await createPaymentIntention();
        
        var modalObject = {
            qrString: modalData.qr,
            checkoutId: modalData.id,
            deeplink:  {
                url: modalData.deeplink,
                callbackURL: window.location.href,
                callbackURLSuccess: modalData.successLink
            },
            callbackURL: modalData.successLink,
            refreshData: createPaymentIntention,
            onSuccess: onSuccessModo,
            onFailure: onFailureModo,
            onCancel: onCancelModo,
            onClose: onCloseModo,
        }
        modoDataRef.current = modalObject; 
        localStorage.setItem('modoModal', modalData.id);
        const modoSDK = window.ModoSDK;
        modoSDK.modoInitPayment(modalObject);
        setLoading(false);
    }

    const onSuccessModo = () => {
        
    }

    const toggleWaitingModal = () => {
        setWaitingModal(!waitingModal);
    }

    const checkPaymentIntention = async () => {
        const modoModal = localStorage.getItem('modoModal');
        if(modoModal){
            const res = await checkPaymentModo(modoModal);
            return res
        } else {
            let errorMessage = `Cancelaste el pago. Para elegir una nueva forma de pago, haz clic en el botón "Paso Anterior".`;
            setError(errorMessage);    
        }
    }

    const onCloseModo = async () => {
        const res = await checkPaymentIntention();
        let errorMessage = `Cancelaste el pago. Para elegir una nueva forma de pago, haz clic en el botón "Paso Anterior".`;

        if (res) {
            if (res.result) {
                navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`);
            } else if (res.checkoutData.status === 'PROCESSING') {
                toggleWaitingModal();

                let attempts = 0;
                const checkStatus = async () => {
                    if (attempts < 2) {
                        attempts++;
                        setTimeout(async () => {
                            const res = await checkPaymentIntention();
                            if (res.result) {
                                navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`);
                            } else if (attempts === 2 && (res.checkoutData.status === 'REJECTED' || res.checkoutData.status === 'PROCESSING')) {
                                toggleWaitingModal(); // Cierra el modal después de la segunda verificación
                            } else {
                                checkStatus(); // Llama de nuevo si no ha llegado a 2 intentos
                            }
                        }, 5000);
                    }
                };

                checkStatus();
            } else if (res.checkoutData.status === 'REJECTED') {
                errorMessage = `El Pago no pudo ser Procesado. Para elegir una nueva forma de pago, haz clic en el botón "Paso Anterior".`;
            }
        }

        setError(errorMessage);
    }

    const onFailureModo = () => {
        setError(`Hubo un error al recibir tu pago. Para elegir una nueva forma de pago, haz clic en el botón "Paso Anterior".`);
    }

    const onCancelModo = () => {
        setError(`Cancelaste el pago. Para elegir una nueva forma de pago, haz clic en el botón "Paso Anterior".`);
    }
    
    return (
        <div>
            <Modal isOpen={waitingModal} toggle={toggleWaitingModal} centered={true}>
                <ModalHeader toggle={toggleWaitingModal}>PAGO EN PROCESO</ModalHeader>
                <ModalBody className='error-handler'>
                    <p className='text-center error-message'>Pago en Proceso, Aguarda unos segundos</p>
                    <div className='d-flext justify-content-center p-0 mb-2'>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                                <Spinner className='text-center' style={{ "top": "5%", "left": "28%" }} />
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col md={12} className='pb-2 pt-2'>
                              
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>
            {isLoading && 
                <>
                    <Row>
                        <Col>
                            <h1 className='mt-5 text_3 general-title text-center'>Estamos procesando</h1>
                            <h1 className='text_3 general-title text-center'>tu pago...</h1>
                        </Col>
                    </Row>
                    <Row>                                                                                                                                                  
                        <Col>
                        <h1 className='text_3 general-title text-center'>Aguarda unos segundos...</h1>
                        </Col>
                    </Row>
                    <Row>                                                                                                                                                  
                        <Col>
                            <Spinner className='text-center' style={{"top":"40%", "left":"40%"}}/>
                        </Col>
                    </Row>
                </>
            }
                
            {!isLoading && <>
                <Row>
                    <Col>
                        <h4 className='subtitles'>Pago con QR MODO</h4>
                    </Col>
                </Row>
                <Row>                                                                                                                                                  
                    <Col>
                        <p><strong>IMPORTANTE:</strong> {error}</p>
                    </Col>
                </Row>
                
            </>}
        </div>
    );
};

export default TipoPagoModo;