export default class SeatWidget {
    constructor() {
        loadScript();
    }
}

const loadScript = () => {
    const url = `${window.location.origin}/js/seatwidget-compras-pack.min.js?v=${new Date().getTime()}`;
    if (isScriptExist(url)) {
        deleteScript();
    }
    createScript(url);
}

const createScript = (url: string) => {
    var s = document.createElement("script");
    s.type = `text/javascript`;
    s.src = url;
    s.id = 'seatwidget';
    document.head.appendChild(s);
}

const deleteScript = () => {
    const scriptElement = document.getElementById('seatwidget');
    scriptElement?.parentNode?.removeChild(scriptElement);
}

const isScriptExist = (url: string) => {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src === url) return true;
    }
    return false;
}