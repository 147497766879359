import { useEffect } from 'react';
import { checkPaymentModo, redSysValidatePayment } from '../../api/purchase';
import { useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';

const CompraRevalidarMODO = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        (async () => {
            if (id) {
                const res = await checkPaymentModo(id);
                if (res.result) {
                    navigate(`/pago-finalizado/debitCreditCard/${res.checkoutData.code}`)
                }
            }
        })()
    }, [navigate])

    return (
        <div className='vh-100'>
            <h1 className='mt-5 text_3 general-title text-center'>Estamos procesando</h1>
            <h1 className='text_3 general-title text-center'>tu pago...</h1>
            <h1 className='text_3 general-title text-center'>Aguarda unos segundos...</h1>
            <Spinner className='text-center' style={{"top":"40%", "left":"40%"}}/>
        </div>
    );
};

export default CompraRevalidarMODO;