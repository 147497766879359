import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PurchaseData, datosCompra } from '../../../types/types';
import DetalleCompra from '../compra/DetalleCompra';
import TipoPagoTarjeta from '../compra/TipoPagoTarjeta';
import TipoPagoCash from '../compra/TipoPagoCash';
import TipoPagoDirectPaid from '../compra/TipoPagoDirectPaid';
import { Button, Col, Row } from 'reactstrap';
import Timer from './Timer';
import '../../sass/evento_seleccionado/paso_cuatro.scss';
import TituloEvento from './TituloEvento';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TipoPagoRedsys from '../compra/TipoPagoRedsys';
import { fetchDetalleCompra } from '../../../reducer/events-reducer';
import TipoPagoModo from '../compra/TipoPagoModo';

type configObjectDatosComprados = {
    eventsReducer: {
        datosCompraPasoDos: PurchaseData;
        detalleCompra: datosCompra;
        cardNumber: string | null;
    };
};

const PasoCuatroEvento = ({ datosCompraPasoDos, detalleCompra, setTimeOut, cardNumber }: { datosCompraPasoDos: PurchaseData, detalleCompra: datosCompra, setTimeOut: React.Dispatch<React.SetStateAction<boolean>>, cardNumber: string | null }) => {

    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get('type');
    const [pasoAnterior, setPasoAnterior] = useState<number>(3);
    const [viewComissionText, setViewComissionText] = useState<boolean>(true);

    const handlePasoAnterior = (stepNumber:number): void => {
        setPasoAnterior(stepNumber);
    };

    const handleViewComissionText = (): void => {
        setViewComissionText(false);
    }
    
    useEffect(() => {

        if(typeParam !== 'debitCreditCard') {
            handleViewComissionText();
        }
        
        fetchDetalleCompra();
    }, [fetchDetalleCompra]);

    return (
        <>
            <Row className='compra-paso-cuatro'>
                <Row>
                    {detalleCompra && <TituloEvento />}
                </Row>
                <Col md={6} className='ps-0'>
                    <div>
                        {typeParam === 'debitCreditCard' && <TipoPagoTarjeta handlePasoAnterior={handlePasoAnterior} handleViewComissionText={handleViewComissionText} presetedCardNumber={cardNumber} purchaseCode={null} />}
                        {typeParam === 'cash' && <TipoPagoCash eventType={0}/>}
                        {typeParam === 'directPaid' && <TipoPagoDirectPaid />}
                        {typeParam === 'modo' && <TipoPagoModo />}
                        {typeParam === 'redsys' && <TipoPagoRedsys handlePasoAnterior={handlePasoAnterior} />}
                    </div>
                </Col>
                <Col md={6}>
                    {detalleCompra && <DetalleCompra pasoTres={true} viewComissionText={viewComissionText} />}
                    <Row className='mt-4 steps-buttons'>
                        <Col className='d-flex justify-content-center'>
                            <Button className='back_2' onClick={() => {
                                    if (pasoAnterior === 4) {
                                        window.location.reload();
                                    }
                                    window.history.go(-1);
                                }}><ArrowBackIosIcon /> PASO ANTERIOR</Button>
                        </Col>
                    </Row>
                    <Timer setTimeOut={setTimeOut} />
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        datosCompraPasoDos: state.eventsReducer.datosCompraPasoDos,
        detalleCompra: state.eventsReducer.detalleCompra,
        cardNumber: state.eventsReducer.cardNumber
    }
};

export default connect(
    mapStateToProps,
    {fetchDetalleCompra}
)(PasoCuatroEvento);
