import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getLangValue } from '../../api/config';

const EventosImportantes = () => {
    
    const [lang] = useState<string>(`${getLangValue("lang")}`);

    useEffect(()=>{

        if(lang === 'es_ES'){
            let alternateAr = document.getElementById("alternate_ar") as HTMLLinkElement;
            alternateAr.href = "https://www.entradaweb.com.ar";

            let alternateEs = document.getElementById("alternate_es") as HTMLLinkElement;
            alternateEs.href = "https://www.entradaweb.es/los-30-eventos-mas-populares";

            let alternateDefault = document.getElementById("alternate_default") as HTMLLinkElement;
            alternateDefault.href = "https://www.entradaweb.com";

            const script = document.createElement('script')
            script.type = 'application/ld+json';
            script.text = JSON.stringify({
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": {
                  "@type": "Question",
                  "name": "¿Cuál es la mejor plataforma para comprar y vender entradas en España?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Entradaweb es la plataforma online destacada. Como líder en España, Entradaweb.es ofrece una experiencia sin complicaciones para comprar y vender entradas. Con una interfaz intuitiva y seguridad garantizada, Entradaweb.es simplifica el proceso, permitiéndote disfrutar al máximo de tus eventos favoritos desde cualquier lugar del país."
                  }
                }
              });
            document.head.appendChild(script)

            const script2 = document.createElement('script')
            script2.type = 'application/ld+json';
            script2.text = JSON.stringify({
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Entradaweb España",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Yécora 57, Bajo A",
                  "addressLocality": "Madrid",
                  "postalCode": "28022",
                  "addressCountry": "ES"
                },
                "telephone": "+34640969287",
                "email": "info@entradaweb.es",
                "url": "https://www.entradaweb.es/",
                "openingHours": "Mo-Fr 09:00-18:00",
                "image": "https://www.entradaweb.es/images/logo.png",
                "priceRange": "$$",
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 40.438131,
                  "longitude": -3.624481
                }
              });
            document.head.appendChild(script2)

            const script3 = document.createElement('script')
            script3.type = 'application/ld+json';
            script3.text = JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Compra y vende eventos online en España",
                    "item": "https://www.entradaweb.es/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Cómo vender entradas para tu evento en España",
                    "item": "https://www.entradaweb.es/vender-entradas"
                  },
                  {
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Los eventos más populares en España",
                    "item": "https://www.entradaweb.es/los-30-eventos-mas-populares"
                  },
                  {
                    "@type": "ListItem",
                    "position": 4,
                    "name": "Los eventos más populares en España",
                    "item": "https://www.entradaweb.es/25-eventos-importante-en-espana-2025"
                  }
                ]
              });
            document.head.appendChild(script3)
        }

    },[])

    return (<>
     {lang === 'es_ES' && <Container>
        <Row>
            <Col className='text-start mt-4'>
                                
                <h1 className='text_2 mb-4' style={{"textAlign": "center"}}>25 Eventos más importantes en España en 2025</h1>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>Descubre los <strong>25 eventos más importantes de España en 2025</strong>, desde festivales hasta competiciones deportivas, y por qué Entradaweb.es es la mejor opción para <strong><a href="https://www.entradaweb.es/vender-entradas" title="Vender entradas online">vender entradas online</a></strong>.</p>
                <p style={{"color": "#34495E", "lineHeight": "1.6"}}>España será el epicentro de numerosos eventos deportivos, culturales, musicales y gastronómicos en 2025. Aquí te ofrecemos una lista completa de los eventos más destacados, con fechas, detalles y todo lo que necesitas saber.</p>
                <Row className='mb-4'>
                    <Col className='d-flex justify-content-center'>
                        <img src="https://imgs.entradaweb.es/principales-eventos-espana-2025.webp"style={{"width":"80%"}} className='img-fluid' alt="Descubre los 25 eventos más importantes en España 2025" />
                    </Col>
                </Row>               
                <h2 className="text_2 col-md-12 mt-3"><strong><a href="#el-mejor-sitio-para-vender-entradas-online">Por qué Entradaweb.es es el mejor sitio para vender entradas online</a></strong></h2>
                

                <h2 className="text_2 col-md-8 mt-3" id="los-eventos-mas-populares"><strong>Eventos deportivos destacados</strong></h2>    
                <ol>
                    <li>
                        <strong>Partido de la NFL en Madrid</strong><br/>
                        Por primera vez, España será sede de un partido oficial de la <strong>National Football League (NFL)</strong>. Este importante evento tendrá lugar en el icónico estadio <strong>Santiago Bernabéu</strong>, con un emocionante enfrentamiento entre los <strong>Miami Dolphins</strong> y los <strong>Chicago Bears</strong>. Aunque la fecha exacta aún no está confirmada, este partido marca un paso clave para popularizar el fútbol americano en el país.
                    </li>
                    <li>
                        <strong>La Vuelta Femenina 2025</strong><br/>
                        Del <strong>4 al 10 de mayo</strong>, Barcelona será el punto de partida de la prestigiosa competición ciclista femenina, <strong>La Vuelta Femenina</strong>. Esta elección reafirma la posición de Barcelona como un epicentro deportivo global, tras su protagonismo en eventos ciclísticos recientes.
                    </li>
                    <li>
                        <strong>Final de la UEFA Europa League</strong><br/>
                        El <strong>21 de mayo</strong>, el estadio de San Mamés en Bilbao será el escenario de la <strong>final de la UEFA Europa League</strong>, uno de los torneos de fútbol más importantes a nivel europeo.
                    </li>
                    <li>
                        <strong>Gran Premio de España de Fórmula 1</strong><br/>
                        Del <strong>30 de mayo al 1 de junio</strong>, el Circuito de Cataluña, situado a menos de 30 kilómetros de Barcelona, acogerá el <strong>Gran Premio de España de Fórmula 1</strong>, una cita imprescindible para los amantes del automovilismo.
                    </li>
                    <li>
                        <strong>Gran Premio de Cataluña de Motociclismo</strong><br/>
                        Los días <strong>5, 6 y 7 de septiembre</strong>, el Circuito de Cataluña será sede del <strong>Gran Premio de Cataluña de Motociclismo</strong>, parte del Campeonato Mundial de MotoGP.
                    </li>
                    <li>
                        <strong>Campeonatos Europeos de Atletismo por Equipos</strong><br/>
                        En junio, España acogerá los <strong>Campeonatos Europeos de Atletismo por Equipos</strong>, donde las mejores selecciones del continente competirán en diversas disciplinas.
                    </li>
                    <li>
                        <strong>Volvo Ocean Race en Cartagena</strong><br/>
                        En agosto, la prestigiosa regata <strong>Volvo Ocean Race</strong> hará escala en Cartagena, Murcia, ofreciendo una oportunidad única para presenciar esta competencia náutica de renombre mundial.
                    </li>
                    <li>
                        <strong>Campeonato del Mundo IRONMAN 70.3 en Marbella</strong><br/>
                        Los días <strong>8 y 9 de noviembre</strong>, Marbella acogerá el <strong>Campeonato del Mundo IRONMAN 70.3</strong>, un evento que reúne a los mejores triatletas del mundo.
                    </li>
                </ol>
                <section>
                <h2 className="text_2 col-md-8 mt-3"><strong>Festivales culturales y musicales</strong></h2>
                <ol start={9}>
                    <li>
                        <strong>Benidorm Fest 2025</strong><br/>
                        El famoso <strong>Benidorm Fest</strong> regresa con dos semifinales los días <strong>28 y 30 de enero</strong>, y una gran final el <strong>1 de febrero</strong>. Este festival es conocido por descubrir nuevos talentos musicales y promover la diversidad artística.
                    </li>
                    <li>
                        <strong>Festival Internacional de Circo de Albacete</strong><br/>
                        En <strong>febrero</strong>, Albacete se convierte en la capital mundial del circo con la <strong>décimo octava edición</strong> de este festival. Artistas de diversos países compiten en un evento que celebra la magia del arte circense.
                    </li>
                    <li>
                        <strong>LOS40 Primavera Pop 2025</strong><br/>
                        Los días <strong>11 y 12 de abril</strong>, Madrid y Badalona serán las sedes de este popular festival de música pop. Contará con actuaciones de artistas nacionales e internacionales.
                    </li>
                    <li>
                        <strong>Vigo Porté 2025</strong><br/>
                        En abril, Vigo acoge el evento <strong>Vigo Porté</strong>, uno de los festivales de danza más importantes del país.
                    </li>
                </ol>
            </section>
            <section>
                <h2  className="text_2 col-md-12 mt-3" id="el-mejor-sitio-para-vender-entradas-online"><strong>Por qué Entradaweb.es es el mejor sitio para vender entradas online</strong></h2>
                <p>Si estás buscando la manera más efectiva de <strong><a href="https://www.entradaweb.es/vender-entradas" title="Vender entradas online">vender entradas online</a></strong> para eventos, conciertos, deportes, cine, teatro, <strong><a href="https://www.entradaweb.es" title="Entradaweb">Entradaweb.es</a></strong> es la plataforma ideal. Con su interfaz intuitiva, un sistema de pagos seguro y una amplia red de distribución, Entradaweb.es se ha convertido en la solución preferida para organizadores y compradores.</p>
                <p>Además, ofrece herramientas avanzadas para promocionar tus eventos, como estadísticas de ventas en tiempo real, integraciones con redes sociales y atención personalizada. Ya sea para un festival de música, un partido de fútbol o un espectáculo cultural, <strong>Entradaweb.es</strong> garantiza una experiencia fluida y profesional para todos los usuarios.</p>
            </section>
            <p>© 2025 Entradaweb.es. Todos los derechos reservados. Tu mejor opción para comprar y <strong><a href="https://www.entradaweb.es/vender-entradas" title="Vender entradas online">vender entradas online</a></strong> en España.</p>
            </Col>
        </Row>
     </Container>}
    </>);
};
export default EventosImportantes;