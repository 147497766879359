import React, { Dispatch, useEffect, useState } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { eventoSeleccionado, GenericObject } from '../../../types/types';
import FechaYHoraSimple from './FechaYHoraSimple';
import SeleccionarFechaYHora from './SeleccionarFechaYHora';
import UbicacionFotos from './UbicacionFotos';
import InfoGeneral from './InfoGeneral';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { parseJWt } from '../../../utilities/utilities';
import TwitterIcon from '../../../utilities/TwitterIcon';
import '../../sass/evento_seleccionado/paso_uno.scss';
import SeleccionarSoloFecha from './SeleccionarSoloFecha';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '../../components/Button'
import { comprarPasoDos } from '../../../api/purchase'
import { useNavigate } from 'react-router-dom';
import { getLangValue } from '../../../api/config';
import { Carousel } from 'react-responsive-carousel';
import EventoFotos from './EventoFotos';
import { setCard } from '../../../reducer/events-reducer';
import TextField from '../TextField';
import { mostrarError } from '../../../reducer/config-reducer';


interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    idVendedor: string,
    userId: string,
    mostrarError:Dispatch<GenericObject | null>,
    setCard: (cardNumber: string | null) => void;
}

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
        idVendedor: string;
        userId: string;
    }
}

declare global {
    interface Window { loadFacebookPixel: any; }
}

window.loadFacebookPixel = window.loadFacebookPixel || {};

const PasoUnoEvento = (props: propiedades) => {
    const [horaSeleccionada, setHoraSeleccionada] = useState('')
    const navigate = useNavigate();
    const currentUrl = `${getLangValue("front")}/${window.location.pathname}`;
    const [referrer, setReferrer] = useState<string | null>('');
    const [error, setError] = useState<string>('');
    const [cardNumber, setCardNumber] = useState<string | null>('');
    const shareButtonProps = {
        url: currentUrl,
        resetButtonStyle: false
    };
    const { eventoSeleccionado, idVendedor, userId, setCard } = props;
    
    useEffect(() => {
        const handlePageShow = (event: any) => {
            if (event.persisted) {
                console.log('Página restaurada desde el caché, recargando...');
            window.location.reload();
            }
        };
        
        window.addEventListener("pageshow", handlePageShow);
        return () => window.removeEventListener("pageshow", handlePageShow);
    }, []);

    useEffect(() => {
        if (horaSeleccionada && localStorage.getItem('token')) {
            if (horaSeleccionada !== parseJWt(localStorage.getItem('token')!).sessionEventId) {
                localStorage.removeItem('token')
            }
        }
        if (localStorage.getItem('referrer')) {
            setReferrer(localStorage.getItem('referrer'));
        }
    }, [horaSeleccionada])

    useEffect(() => {
        if (eventoSeleccionado.pixelFacebook1) {
            window.loadFacebookPixel(eventoSeleccionado.pixelFacebook1)
        }
        if (eventoSeleccionado.pixelFacebook2) {
            window.loadFacebookPixel(eventoSeleccionado.pixelFacebook2)
        }
    }, [eventoSeleccionado]);



    const nextStep = (token: string) => {
        localStorage.setItem('token', token);
        const hash = window.location.pathname.split("/")[2];
        navigate(`/evento/${hash}/step/2`)
    }

    const buyTicketHandler = async () => {
        if (eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length === 1) {
            try {
                const res = await comprarPasoDos(eventoSeleccionado.sessions[0].hours[0].code, idVendedor, referrer, cardNumber, userId);
                if (res) {
                    localStorage.removeItem('referrer')
                    nextStep(res.token)
                    setCard(cardNumber);
                }    
            } catch (error: any) {
                props.mostrarError({mensajeAMostrar: error!.message});
            }
        } else {
            if (horaSeleccionada) {
                try {
                    const res = await comprarPasoDos(horaSeleccionada, idVendedor, referrer, cardNumber, userId);
                    if (res) {
                        localStorage.removeItem('referrer')
                        nextStep(res.token)
                        props.setCard(cardNumber);
                    }   
                } catch (error: any) {
                    props.mostrarError({mensajeAMostrar: error!.message});
                }
            }
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let str:string
        var pattern = /^([0-9]+)?$/;
            if (pattern.test(event.target.value)) {
                str = event.target.value;
            }else{
                return;
            }
        
        setCardNumber(str);
    }

    const volverAtras = () => {
        navigate(`/`)
    }

    const RecomiendaEsteEvento = () => {
        return (<>
            <Row>
                <Col className='text-center'><strong>RECOMIENDA ESTE EVENTO</strong></Col>
            </Row>
            <Row>
                <Col className='d-flex justify-content-center pb-4 social-share-link-icons'>
                    <FacebookShareButton {...shareButtonProps}>
                        <FacebookIcon />
                    </FacebookShareButton>
                    <TwitterShareButton  {...shareButtonProps}>
                        <TwitterIcon />
                    </TwitterShareButton>
                    <WhatsappShareButton {...shareButtonProps}>
                        <WhatsAppIcon />
                    </WhatsappShareButton>
                </Col>
            </Row>
        </>)
    }

    const formattedInfoPrices = eventoSeleccionado.infoPrices.replace(/\n/g, '<br />');
    return (
        <>
            {!eventoSeleccionado.image &&
                <Row>
                    <Col> <p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: eventoSeleccionado.title }} /></Col>
                </Row>}
            <Row>
                {(eventoSeleccionado.image || eventoSeleccionado.description) && <Col md={7} className='p-0 mt-4'>
                    {eventoSeleccionado.image && eventoSeleccionado.eventImages.length === 1 && <Row>
                        <Col className='mb-2'>
                            <div style={{ "position": "relative" }}>
                                <img src={eventoSeleccionado.image!} alt="" className='img-fluid w-100' />
                                {eventoSeleccionado.status === '0' &&
                                    <div className='position-absolute start-0 w-100' style={{ "top": "50%", "marginTop": "-6em" }}>
                                        <img src="/images/agotado.png" alt="agotado" className='w-100' />
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>}
                    {eventoSeleccionado.eventImages.length > 1 && <Row>
                        <Col className='mb-2'>
                            <Carousel autoPlay showArrows={true} showThumbs={false} showStatus={false} dynamicHeight={true} infiniteLoop>
                            {eventoSeleccionado.eventImages.map((media, index) => {
                                return (
                                    <div key={index}>
                                        <img src={media.url_large} alt={media.title} />
                                        {eventoSeleccionado.status === '0' &&
                                            <div className='position-absolute start-0 w-100' style={{ "top": "50%", "marginTop": "-6em" }}>
                                                <img src="/images/agotado.png" alt="agotado" className='w-100' />
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            </Carousel>
                        </Col>
                    </Row>}
                    <Row>
                        <Col>
                            {eventoSeleccionado.description && <>
                                <Row>
                                    <Col className='ps-4 mb-4'><div dangerouslySetInnerHTML={{ __html: eventoSeleccionado.description }} /></Col>
                                </Row>
                            </>}
                        </Col>
                    </Row>
                    {eventoSeleccionado.eventImagesVideos.length > 0 && <EventoFotos medialist= {eventoSeleccionado.eventImagesVideos}/>}
                    <RecomiendaEsteEvento />
                </Col>}

                <Col md={(!eventoSeleccionado.image && !eventoSeleccionado.description) ? 12 : 5} className={(!eventoSeleccionado.image && !eventoSeleccionado.description) ? 'mt-3 no-image-no-description' : 'mt-3'}>
                    <Row>
                        <Col>
                            <div className='rounded-circle abajo-icon float-end mt-2  animate__animated animate__bounce'><KeyboardArrowDownIcon /></div>
                        </Col>
                    </Row>
                    <Row className='compra-paso-uno'>
                        <Col className='text-start event-data'>
                            <div className='columna-event-data'>
                                {function () {
                                    if (eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length === 1) {
                                        return (<>
                                            <h4 className='mt-2 subtitles'>Fecha y Hora</h4>
                                            <FechaYHoraSimple fecha={eventoSeleccionado.sessions[0].title} hora={eventoSeleccionado.sessions[0].hours[0].hour} status={eventoSeleccionado.status} showBadgetAgotado={!eventoSeleccionado.image} />
                                            <h4 className={'mt-4 subtitles'}>Lugar</h4>
                                        </>)

                                    } else {
                                        return (<h4 className={'mt-2 subtitles'}>Lugar</h4>);
                                    }
                                }()}
                                <UbicacionFotos
                                    lugar={eventoSeleccionado.place}
                                    address={eventoSeleccionado.address ? eventoSeleccionado.address : null}
                                    map={eventoSeleccionado.map}
                                    medialist={eventoSeleccionado.mediaList}
                                    location={eventoSeleccionado.location}
                                />
                                {(!eventoSeleccionado.image && !eventoSeleccionado.description) && <RecomiendaEsteEvento />}
                            </div>
                            <div className='columna-event-data'>
                                {eventoSeleccionado.infoPrices !== '' && <><h4 className='mt-2 subtitles'>Precios</h4>
                                    <Row>
                                        <Col className='ps-4' dangerouslySetInnerHTML={{ __html: formattedInfoPrices }} />
                                    </Row></>}
                                {eventoSeleccionado.sessions && <>
                                    <h4 className='mt-4 subtitles'>Info General</h4>
                                    <InfoGeneral
                                        duration={eventoSeleccionado.duration}
                                        classification={eventoSeleccionado.classification}
                                    /></>}
                                <Row>
                                    <div className='buy-tickets'>
                                        {(function () {
                                            if (eventoSeleccionado.sessions && eventoSeleccionado.sessions.length > 1 && !eventoSeleccionado.oneSessionPerDay) {
                                                return (<>
                                                    <SeleccionarFechaYHora sesiones={eventoSeleccionado.sessions} status={eventoSeleccionado.status} horaSeleccionada={horaSeleccionada} setHoraSeleccionada={setHoraSeleccionada} />
                                                </>)
                                            } else if (eventoSeleccionado.sessions && ((eventoSeleccionado.sessions.length > 1 && eventoSeleccionado.oneSessionPerDay) || (eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length > 1))) {
                                                return (<> <SeleccionarSoloFecha sesiones={eventoSeleccionado.sessions} status={eventoSeleccionado.status} horaSeleccionada={horaSeleccionada} setHoraSeleccionada={setHoraSeleccionada} /></>)
                                            }
                                        })()}
                                        {eventoSeleccionado.requireCreditCard && eventoSeleccionado.sessions &&
                                                        <>
                                                        <h4 className='subtitles'>Ingresa el Número de Tarjeta con la que vas a Pagar</h4>
                                                        <TextField required={true} type='text' id='numeroTarjeta' label='Sólo números, sin espacios ni puntos' value={cardNumber ? cardNumber : ""} errorMsg={error} handleInputChange={handleInputChange} focusEvent={() => { setError(''); }} />
                                                        </>
                                                }
                                        {(function () {
                                            if (eventoSeleccionado.status === "0") {
                                                return (
                                                    <>
                                                        <Button className='back_2 p-3 ps-4 pe-4 mt-4' onClick={volverAtras}> IR AL INICIO </Button>
                                                    </>
                                                );
                                            } else if (eventoSeleccionado.sessions) {
                                                return (<>

                                                    <Button disabled={(horaSeleccionada === "" && !(eventoSeleccionado.sessions && eventoSeleccionado.sessions.length === 1 && eventoSeleccionado.sessions[0].hours.length === 1))|| (eventoSeleccionado.requireCreditCard && (cardNumber === null || cardNumber === "" || cardNumber.length < 6))} onClick={buyTicketHandler}>{eventoSeleccionado.paymentType === 1 ? 'COMPRA' : 'OBTIENE'} TUS ENTRADAS &gt;</Button>
                                                </>);
                                            }
                                        })()}
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
        idVendedor: state.eventsReducer.idVendedor,
        userId: state.eventsReducer.userId
    }
}

export default connect(
    mapStateToProps,
    {setCard, mostrarError}
)(PasoUnoEvento);
